var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-center"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("profile.sign-details")) + " ")]), _vm.signatureUrl ? _c('div', {
    staticClass: "signature-pad",
    staticStyle: {
      "width": "100%",
      "max-width": "300px",
      "height": "200px",
      "background-size": "contain",
      "background-repeat": "no-repeat",
      "background-position": "center center"
    },
    style: 'background-image: url(' + _vm.signatureUrl + ')'
  }) : _vm._e(), _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modalSignature",
      modifiers: {
        "modalSignature": true
      }
    }],
    staticClass: "btn-secondary btn btn-sm"
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.set-sign")) + " ")])])]), _c('b-modal', {
    ref: "modalSignature",
    attrs: {
      "id": "modalSignature",
      "title": "Ketetapan Tandatangan",
      "ok-title": "Simpan",
      "cancel-title": "Batal"
    },
    on: {
      "cancel": _vm.clearSignaturePad,
      "ok": _vm.saveSignature
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("profile.signature")))]), _c('div', {
    staticClass: "shadow signature-pad"
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "100%",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0%",
      "left": "0%",
      "top": "45%",
      "color": "#b6b6b4",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign-here")) + " ")])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }